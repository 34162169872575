.sentence-markup {
  ruby {
    display: inline-flex;
    flex-direction: column-reverse;
  }

  rt {
    display: block;
    line-height: 1.4;
    font-weight: 700;
    font-family: heisei-maru-gothic-std;
  }

  rb {
    display: inline;
    line-height: 1.4;
  }
}

.ProseMirror {
  > * + * {
    margin-top: 0.3em;
  }

  ul,
  ol {
    padding: 0 2.5rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
    color: gray;
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}

.ProseMirror.focus-visible {
  outline: none;
}

.bunset-words {
  ruby {
    display: inline-flex;
    flex-direction: column-reverse;
  }

  rt {
    display: block;
    line-height: 1.4;
    font-weight: 700;
    color: #ffffff;
    font-family: heisei-maru-gothic-std;
  }

  rb {
    display: inline;
    line-height: 1.4;
  }

  &:hover {
    rt {
      display: block;
      line-height: 1.4;
      font-weight: 700;
      color: purple;
      font-family: heisei-maru-gothic-std;
    }
  }
}
